<template>
  <div style="background-color: #F5F5F5; padding-bottom: 40px; height: 100%; position: fixed; width: 100%">
    <van-nav-bar
        v-if="!isInMini()"
        id="nav-bar"
        title="订单支付"
        left-text="返回"
        left-arrow
        @click-left="$router.back()"
    />
    <div style="overflow: scroll" id="scroll">
      <div class="cabinet">
        <span style="color: #333333;font-size: 15px; font-weight: bold">取餐柜</span>
        <div v-if="largeAmount > 0">
          <div style="display: flex; justify-content: space-between; margin-top: 20px">
            <span style="font-size: 14px; font-weight: 500; color: #666666;">大格子x{{largeAmount}}</span>
            <span style="font-size: 14px; font-weight: bold; color: #333333;">￥{{ (largeMoney / 100).toFixed(2)}}</span>
          </div>
          <div style="margin-top: 16px; background-color: #F5F5F5; border-radius: 4px; padding: 10px; color: #666666; font-size: 14px; font-weight: 500">{{largeCellText}}</div>
        </div>
        <div v-if="smallAmount > 0">
          <div style="display: flex; justify-content: space-between; margin-top: 20px">
            <span style="font-size: 14px; font-weight: 500; color: #666666;">小格子x{{smallAmount}}</span>
            <span style="font-size: 14px; font-weight: bold; color: #333333;">￥{{ (smallMoney / 100).toFixed(2)}}</span>
          </div>
          <div style="margin-top: 16px; background-color: #F5F5F5; border-radius: 4px; padding: 10px; color: #666666; font-size: 14px; font-weight: 500">{{smallCellText}}</div>
        </div>
        <div style="display: flex; justify-content: flex-end; margin-top: 20px; font-family: PingFang SC; color: #333333; font-weight: bold; font-size: 16px">
          <span style="margin-right: 18px">共{{largeAmount + smallAmount}}格</span>合计： <span style="color: #BD0404">¥{{ ((largeMoney + smallMoney) / 100).toFixed(2) }}</span>
        </div>
      </div>
<!--      <div class="cabinet">
        <span style="color: #333333;font-size: 15px; font-weight: bold">订单详情</span>
        <div style="display: flex; justify-content: space-between; margin-top: 20px">
          <span style="font-size: 14px; font-weight: 500; color: #666666;">订单号</span>
          <span style="font-size: 14px; font-weight: 500; color: #333333;">{{order['orderSn']}}</span>
        </div>
        <div style="display: flex; justify-content: space-between; margin-top: 20px">
          <span style="font-size: 14px; font-weight: 500; color: #666666;">下单时间</span>
          <span style="font-size: 14px; font-weight: 500; color: #333333;">{{ order['createTime'] != null ? order['createTime'].replace('T', ' ').substring(0, 19) : ''}}</span>
        </div>
        <div style="display: flex; justify-content: space-between; margin-top: 20px">
          <span style="font-size: 14px; font-weight: 500; color: #666666;">下单地址</span>
          <span style="font-size: 14px; font-weight: 500; color: #333333;">{{order['areaName']}}</span>
        </div>
      </div>-->
      <div v-if="order.userOrderId === undefined || order.userOrderId === ''" class="cabinet">
        <span style="color: #333333;font-size: 15px; font-weight: bold">顾客信息</span>
        <div style="display: flex; justify-content: space-between; margin-top: 20px;align-items: center">
          <span style="font-size: 14px; font-weight: 500; color: #666666;">手机尾号</span>
          <input v-model="mobileTail" type="tel" style="width: 120px; height: 30px;border: 1px solid #CCCCCC;border-radius: 3px" placeholder="请输入顾客手机尾号"/>
        </div>
      </div>
      <div class="cabinet">
        <span style="color: #333333;font-size: 15px; font-weight: bold;">请选择支付方式</span>
        <van-radio-group v-model="payType">
<!--          <van-cell-group>-->
            <van-cell title="微信支付" clickable @click="payType = '1'" center :border="false" style="margin-top: 5px">
              <template #right-icon>
                <van-radio name="1" checked-color="#FFBD04"/>
              </template>
              <template #icon>
                <van-image :src="require('@/assets/pay/wxzf_icon.png')" width="16" height="16" style="margin-right: 10px" />
              </template>
            </van-cell>
            <van-cell title="余额支付" clickable @click="payType = '2'" center :border="false">
              <template #right-icon>
                <van-radio name="2" checked-color="#FFBD04"/>
              </template>
              <template #icon>
                <van-image :src="require('@/assets/pay/yezf_icon.png')" width="16" height="16" style="margin-right: 10px" />
              </template>
            </van-cell>
<!--          </van-cell-group>-->
        </van-radio-group>
      </div>

      <div @click="payOrder" class="submit-button">确定支付（￥{{ ((largeMoney + smallMoney) / 100).toFixed(2) }}）</div>
<!--      <div @click="payOrder('WALLET')" class="submit-button">钱包支付（￥{{ ((largeMoney + smallMoney) / 100).toFixed(2) }}）</div>-->
    </div>

<!--    <van-button :loading="loading" color="#FFBD04" style="width: 90%; margin-top: 30px" round @click="payOrder">支付</van-button>-->

    <!--<div style="text-align: center; margin: 10px">
      支付剩余时间2020-11-13T16:32:24.047
      <van-count-down style="font-size: 20px; margin-top: 10px" :time="60 * 1000" format="mm 分 ss 秒" @finish="countDownFinish"/>
      <span style="margin-top: 20px; font-size: 18px; display: block; color: red">待支付：￥{{this.$route.query.money / 100}}</span>
      <van-button :loading="loading" type="primary" style="width: 70%; margin-top: 30px" round @click="payOrder">支付</van-button>
    </div>
    <van-dialog v-model="showCodeDialog" @confirm="$router.replace('/')">
      <div style="text-align: center; padding: 20px">
        <span>支付成功</span><br>
        <span style="margin-top: 20px; display: block">取餐码<span style="font-size: 20px; margin: 10px">{{$route.query.takeCode}}</span></span>
      </div>
    </van-dialog>-->
  </div>
</template>

<script>
import Client from "@/api";
export default {
  name: "Pay",
  data: function() {
    return {
      loading: false,
      showCodeDialog: false,
      largeAmount: 0,
      smallAmount: 0,
      largeMoney: 0,
      smallMoney: 0,
      largeCellText: '',
      smallCellText: '',
      mobileTail: '',
      order: {},
      payType: '2'
    }
  },
  created() {
    this.order = JSON.parse(localStorage.getItem('order'))
  },
  mounted() {
    let navbar = document.getElementById('nav-bar')
    let navHeight = navbar ? navbar.clientHeight : 0
    document.getElementById('scroll').style.height = window.innerHeight - navHeight + 'px'

    let cellList = JSON.parse(localStorage.getItem("cellList"))
    let largeCellList = cellList.filter(cell => { return cell.compartmentType === 'LARGE'})
    let smallCellList = cellList.filter(cell => { return cell.compartmentType === 'SMALL'})
    this.largeAmount = largeCellList.length
    this.smallAmount = smallCellList.length
    let total = 0
    for (let cell of largeCellList) {
      // total += (cell.openHeat === true ? 80 : 70)
      console.log(cell)
      total += 0
    }
    this.largeMoney = total
    total = 0
    for (let cell of smallCellList) {
      // total += (cell.openHeat === true ? 60 : 50)
      console.log(cell)
      total += 0
    }
    this.smallMoney = total
    let cabinet = JSON.parse(localStorage.getItem('cabinet'))
    this.largeCellText = largeCellList.map(cell => { return cabinet.lockerName + cell.compartmentName }).join('、')
    this.smallCellText = smallCellList.map(cell => { return cabinet.lockerName + cell.compartmentName }).join('、')
  },
  methods: {
    payOrder() {
      /*if ((this.order.userOrderId === undefined || this.order.userOrderId === '') && this.mobileTail.length === 0) {
        this.$toast.fail('请输入顾客手机尾号')
        return
      }*/
      this.loading = true
      if (this.payType === '1') {
        Client.post('/courier/payCourierOrder', {courierOrderId: this.order.courierOrderId, mobileTail: this.mobileTail, payType: 'WEPAY'}).then(res => {
          this.loading = false
          if (res.data.state === 'ok') {
            localStorage.setItem('payResult', JSON.stringify(res.data.courierOrder))
            console.log(res.data.data)
            let payInfo = res.data.data
            // let url = `/pages/wxpay/wxpay?timeStamp=${payInfo.timeStamp}&nonceStr=${payInfo.nonceStr}&package=${payInfo.package}&signType=${payInfo.signType}&paySign=${payInfo.paySign}`
            // console.log(url)
            // eslint-disable-next-line no-undef
            wx.miniProgram.navigateTo({url: `/pages/wxpay/wxpay?timeStamp=${payInfo.timeStamp}&nonceStr=${payInfo.nonceStr}&package=${payInfo.package}&signType=${payInfo.signType}&paySign=${payInfo.paySign}&token=${localStorage.getItem('token')}&tradeId=${payInfo.tradeId}&courierOrderId=${payInfo.courierOrderId}`})
          } else {
            this.$toast.fail(res.data.msg)
          }
        }).catch(err => {
          this.loading = false
          this.$toast.fail(err.response.data.msg)
        })
      } else {
        Client.post('/courier/payCourierOrder', {courierOrderId: this.order.courierOrderId, mobileTail: this.mobileTail}).then(res => {
          this.loading = false
          if (res.data.state === 'ok') {
            localStorage.setItem('payResult', JSON.stringify(res.data.data))
            this.navTo('/paySuccess')
          } else {
            this.$toast.fail(res.data.msg)
          }
        }).catch(err => {
          this.loading = false
          this.$toast.fail(err.response.data.msg)
        })
      }
    }
  }
}
</script>

<style scoped>
.cabinet {
  margin: 10px;
  padding: 15px;
  background: #FFFFFF;
  border-radius: 9px;
}
*::placeholder {
  color: #CCCCCC;
  font-size: 12px;
  text-align: center;
}
.submit-button {
  width: 90%;
  height: 48px;
  background: #FFBD04;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5%;
  margin-top: 20px;
  margin-bottom: 40px;

  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #FFFFFF;
}
/deep/ .van-cell {
  padding: 10px 0;
  color: #666666;
}
</style>
